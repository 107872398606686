import React, { FC } from 'react';
import { TModalProps, TModalDeleteProps, ModalWidthEnum } from './Modal.types';
import { SModal, SModalDeleteConfirmation } from './Modal.styles';

const Modal: FC<Omit<TModalProps, 'width'>> = props => {
  return <SModal {...props} />;
};

const ModalDeleteConfirmation: FC<Omit<TModalDeleteProps, 'width'>> = props => {
  return <SModalDeleteConfirmation {...props} />;
};

export default Modal;
export { ModalDeleteConfirmation };
export { ModalWidthEnum };
