import {
  TEditingField,
  TEditingFieldUi,
  TFail,
  TFailUI,
  TFileUI,
  TYesNoOptionType,
} from './generalTypes';
import { TDocPacketGet, TDocPacketGetUI } from './docPacket.types';
import { TSADocPacketGet, TSADocPacketGetUI } from './animalTraining.types';

export type TTenantProperty = {
  propertyId: string;
  name: string;
};

export type TTenantPropertyDB = {
  propertyId: string;
  name: string;
};

export enum ETenantActionDB {
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  COMPLETE_RESIDENCY = 'COMPLETE_RESIDENCY',
  SCAN_QR = 'SCAN_QR',
  NO_ACTION = 'NO_ACTION',
  COMPLETE_RESIDENCY_APPLICATION = 'COMPLETE_RESIDENCY_APPLICATION',
}

export enum ETenantActionUI {
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  COMPLETE_RESIDENCY = 'COMPLETE_RESIDENCY',
  SCAN_QR = 'SCAN_QR',
  NO_ACTION = 'NO_ACTION',
  COMPLETE_RESIDENCY_APPLICATION = 'COMPLETE_RESIDENCY_APPLICATION',
}
export type TTenantDataFromDb = {
  name: string;
  username: string;
  email: string;
  phone: string;
  profilePicture: string | null;
  userId: string;
  requiredAction: ETenantActionDB;
  hasTraining: boolean;
};

export type TTenantDataUI = {
  name: string;
  username: string;
  email: string;
  phone: string;
  profilePicture: string | null;
  userId: string;
  requiredAction: ETenantActionUI;
  hasTraining: boolean;
} | null;

export type TTenantCreateUI = {
  name: string;
  email?: string | null;
  phone?: string | null;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  profilePicture?: TFileUI;
  isNotificationAgreementAccepted: boolean;
};

export type TTenantCreateDB = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
  isNotificationAgreementAccepted: boolean;
};

export type TProfileFieldUpdateUI = {
  value: string;
};
export enum ETenantResidencyStatusUI {
  MOVED_IN = 'Moved In',
  APPROVED = 'Approved',
  PENDING = 'Applicant',
  REJECTED = 'Not Approved',
}
export enum ETenantResidencyStatus {
  MOVED_IN = 'MOVED_IN',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export type TProfilePictureUpdateUI = TProfileFieldUpdateUI;
export type TProfilePictureUpdate = { profilePicture: string | null };

export type TRoommateHasAnimalUi = {
  roommateHasAnimal: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatusUI;
};
export type TRoommateHasAnimal = {
  roommateAnimalStatus: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatus;
};

export type TOwnerHasAnimalUi = {
  hasAnimal: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatusUI;
};
export type TOwnerHasAnimal = {
  animalStatus: boolean;
  id: string;
  residencyStatus: ETenantResidencyStatus;
};

export type TNameUpdateUI = TProfileFieldUpdateUI;
export type TNameUpdate = { name: string };

export type TEmailUpdateUI = TProfileFieldUpdateUI;
export type TEmailUpdate = { email: string };

export type TPhoneUpdateUI = TProfileFieldUpdateUI;
export type TPhoneUpdate = { phone: string | null };

export type TTenantResidencyInfoUI = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  nextLeaseToDate: string | null;
  leasePacketId?: string;
  propertyId: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  residencyStatus: ETenantResidencyStatusUI;
  rejectReason?: string;
  canEdit: boolean;
};

export type TTenantResidencyInfo = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  nextLeaseToDate: string | null;
  leasePacketId?: string;
  propertyId: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  status: ETenantResidencyStatus;
  rejectReason?: string;
  editAllowed: boolean;
};
export type TProfileDetailsUI = {
  name: TEditingFieldUi;
  email: TEditingFieldUi;
  phone: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  hpDocumentPackets?: TDocPacketGetUI[];
  tenantResidencyInfoCards: TTenantResidencyInfoUI[];
};

export type TProfileDetails = {
  name: TEditingField;
  phone: TEditingField;
  email: TEditingField;
  profilePicture: TEditingField;
  userId: string;
  hpDocumentPackets?: TDocPacketGet[];
  tenantResidencyInfoCards: TTenantResidencyInfo[];
};

export type TApplicationHPPacketUI = TDocPacketGetUI & {
  fails?: TFailUI[];
};
export type TApplicationHPPacket = TDocPacketGet & {
  fails: TFail;
};

export type TAnimalMetadataUI = {
  present: boolean;
  description: string | null;
} | null;

export type TAnimalMetadata = {
  present: boolean;
  description: string | null;
} | null;

export type TApplicationProfileUI = Omit<
  TProfileDetailsUI,
  'hpDocumentPackets'
> & {
  fails: TFailUI[];
  documentsFails: TFailUI[];
  hpDocumentPackets: TApplicationHPPacketUI[];
  animalMetadata: TAnimalMetadataUI;
  packets: TSADocPacketGetUI[];
};
export type TApplicationProfile = Omit<TProfileDetails, 'hpDocumentPackets'> & {
  fails: TFail;
  hpDocumentPackets: TApplicationHPPacket[];
  specifiedAnimalMetadata: TAnimalMetadata;
  saDocumentPackets: TSADocPacketGet[];
};

export type TSaMetadataUpdateUI = {
  animalId: string;
  isObservable: TYesNoOptionType;
  description?: string | null;
  isRequiredForDisability: boolean;
};
export type TSaMetadataUpdate = {
  animalId: string;
  trainedForObservableDisability: boolean;
  description?: string | null;
};

export type TEsaMetadataUpdateUI = {
  animalId: string;
  hasEsa: boolean;
  description?: string | null;
};
export type TEsaMetadataUpdate = {
  animalId: string;
  isRequiredForDisability?: TYesNoOptionType;
  hasEsaLetterForAnimal: boolean;
  description?: string | null;
};

export type TOnboardTenantUI = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
};
export type TOnboardTenantDB = {
  name: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string;
  isNotificationAgreementAccepted: boolean;
};
export type TTenantPropertyNameUI = {
  label: string;
  value: {
    propertyId: string;
    name: string;
    picture: string;
  };
};
export type TTenantUnitUI = {
  label: string;
  value: {
    unitId: string;
    name: string;
  };
};
export type TCheckTenantDuplicateDB = {
  propertyName: string;
  unitNumber: string;
  propertyManagementId: string;
};
export type TCheckTenantDuplicateUI = {
  propertyName: TTenantPropertyNameUI;
  unit: TTenantUnitUI;
  propertyManagementId: string;
};

export type TDuplicateTenantDB = {
  id: string;
  name: string;
  username: string;
};
export type TDuplicateTenantUI = {
  id: string;
  name: string;
  username: string;
};
