import styled from 'styled-components';
import {
  DateRangePicker,
  DatePicker,
} from '@landlord-tech/ui-kit-opp/dist/components/DatePicker';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const SDateRangePicker = styled(DateRangePicker)<TDateRangePickerProps>`
  &&& {
  }
`;
const SDatePicker = styled(DatePicker)<TDatePickerProps>`
  &&& {
  }
`;

export { SDateRangePicker, SDatePicker };
