import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EApplicationUrls } from 'data/urls/application.url';
import {
  EApplicationStepTypeUi,
  TApplicationCreateUI,
  TApplicationDetailDB,
  TApplicationDetailUI,
  TApplicationSignUI,
  TApplicationStep,
  TApplicationStepUi,
  TLeasePacket,
  TLeasePacketProperty,
  TLeasePacketPropertyUi,
  TLeasePacketUi,
  TLeasePacketUnit,
  TLeasePacketUnitUi,
  TPaymentDetail,
  TPaymentDetailUi,
  TPMStepDetails,
  TPMStepDetailsUI,
  TPropertyManagementStepData,
  TPropertyManagementStepDataUI,
  TRecordApplicationStatisticsUI,
  TRetractApplicationUI,
  TStepVerification,
  TStepVerificationParams,
  TStepVerificationUI,
} from 'data/types/application.types';
import {
  applicationStepTypeConvertor,
  createApplicationConvertor,
  getApplicationDetailConvertor,
  getApplicationSteps,
  getLeasePacketConverter,
  getLeasePacketPropertyConverter,
  getLeasePacketUnitConverter,
  getPaymentDetailConverter,
  pmStepDetailsConvertor,
  propertyManagementStepDataConvertor,
  recordApplicationStatisticsConvertor,
  retractApplicationConverter,
  signApplicationConvertor,
} from 'data/convertors/application.converters';
import { TSetApplicantsMoveInDateUI } from 'data/types/residency.types';
import { setApplicantsMoveInDateConverter } from 'data/convertors/residency.convertor';

const baseUrl = `${EApplicationUrls.APPLICATION}`;

export const applicationsApi = authSplitApi('application', [
  'application',
  'applications',
  'steps',
  'leasePackets',
  'payment',
  'leasePacketProperties',
  'pmStepDetails',
  'propertyManagements',
  'leasePacketUnits',
]).injectEndpoints({
  endpoints: build => ({
    getApplicationSteps: build.query<
      TApplicationStepUi[],
      { animalId?: string; leasePacketId?: string }
    >({
      query({ animalId, leasePacketId }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.STEP}`,
          method: 'GET',
          params: {
            animalId,
            leasePacketId,
          },
        };
      },
      transformResponse: (data: TApplicationStep[]) =>
        data.map(item => getApplicationSteps.fromDb(item)),
      providesTags: ['steps'],
    }),
    getPMStepDetails: build.query<
      TPMStepDetailsUI,
      { leasePacketId?: string; stepType: EApplicationStepTypeUi }
    >({
      query({ leasePacketId, stepType }) {
        const type = applicationStepTypeConvertor.toDb(stepType);
        return {
          url: `${baseUrl}/${EApplicationUrls.STEP}/${EApplicationUrls.DETAILS}`,
          method: 'GET',
          params: {
            leasePacketId,
            stepType: type,
          },
        };
      },
      transformResponse: (data: TPMStepDetails) =>
        pmStepDetailsConvertor.fromDb(data),
      providesTags: ['pmStepDetails'],
    }),
    getApplicationDetail: build.query<
      TApplicationDetailUI,
      {
        applicationId: string;
        animalId: string;
        animalResidencyId?: string;
      }
    >({
      query({ applicationId, animalId, animalResidencyId }) {
        return {
          url: `${baseUrl}/${applicationId}`,
          method: 'GET',
          params: { animalId, animalResidencyId },
        };
      },
      transformResponse: (data: TApplicationDetailDB) =>
        getApplicationDetailConvertor.fromDb(data),
      providesTags: ['application'],
    }),
    getStepVerification: build.mutation<
      TStepVerificationUI,
      TStepVerificationParams
    >({
      query({ animalId, leasePacketId, type }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.STEP}/${EApplicationUrls.VERIFY}`,
          method: 'GET',
          params: {
            animalId,
            leasePacketId,
            type: applicationStepTypeConvertor.toDb(type),
          },
        };
      },
      transformResponse: (data: TStepVerification) => data,
    }),
    createApplication: build.mutation<string, TApplicationCreateUI>({
      query(data) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: createApplicationConvertor.toDb(data),
        };
      },
      invalidatesTags: ['applications'],
    }),
    signApplication: build.mutation<string, TApplicationSignUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EApplicationUrls.SIGN}`,
          method: 'PATCH',
          body: signApplicationConvertor.toDb(data),
        };
      },
      invalidatesTags: ['application'],
    }),
    recordApplicationStatistics: build.mutation<
      void,
      TRecordApplicationStatisticsUI
    >({
      query(data) {
        return {
          url: `${baseUrl}/${EApplicationUrls.METRIC}`,
          method: 'POST',
          body: recordApplicationStatisticsConvertor.toDb(data),
        };
      },
    }),
    getLeasePackets: build.query<TLeasePacketUi[], void>({
      query() {
        return {
          url: `${EApplicationUrls.LEASE_PACKET}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLeasePacket[]) =>
        data.map(item => getLeasePacketConverter.fromDb(item)),
      providesTags: ['leasePackets'],
    }),
    getLeasePacketProperties: build.query<TLeasePacketPropertyUi[], string>({
      query(leasePacketId) {
        return {
          url: `${EApplicationUrls.LEASE_PACKET}/${EApplicationUrls.PROPERTIES}`,
          method: 'GET',
          params: { leasePacketId },
        };
      },
      transformResponse: (data: TLeasePacketProperty[]) =>
        data.map(item => getLeasePacketPropertyConverter.fromDb(item)),
      providesTags: ['leasePacketProperties'],
    }),
    getPaymentDetails: build.query<
      TPaymentDetailUi,
      { animalId: string; leasePacketId?: string }
    >({
      query({ animalId, leasePacketId }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.STEP}/${EApplicationUrls.PAYMENT}`,
          method: 'GET',
          params: {
            animalId,
            leasePacketId,
          },
        };
      },
      transformResponse: (data: TPaymentDetail) =>
        getPaymentDetailConverter.fromDb(data),
      providesTags: ['payment'],
    }),
    getLeasePacketUnits: build.query<
      TLeasePacketUnitUi[],
      { leasePacketId: string; propertyId: string }
    >({
      query({ leasePacketId, propertyId }) {
        return {
          url: `${EApplicationUrls.LEASE_PACKET}/${EApplicationUrls.PROPERTY}/${EApplicationUrls.UNITS}`,
          method: 'GET',
          params: {
            leasePacketId,
            propertyId,
          },
        };
      },
      transformResponse: (data: TLeasePacketUnit[]) =>
        data.map(item => getLeasePacketUnitConverter.fromDb(item)),
      providesTags: ['leasePacketUnits'],
    }),
    getPropertyManagements: build.query<
      TPropertyManagementStepDataUI,
      string | void
    >({
      query(animalId) {
        return {
          url: `${baseUrl}/${EApplicationUrls.STEP}/${EApplicationUrls.PROPERTY_MANAGEMENT}`,
          method: 'GET',
          ...(animalId && { params: { notAppliedYet: animalId } }),
        };
      },
      providesTags: ['propertyManagements'],
      transformResponse: (data: TPropertyManagementStepData) =>
        propertyManagementStepDataConvertor.fromDb(data),
    }),
    retractApplication: build.mutation<void, TRetractApplicationUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.RETRACT}`,
          method: 'PATCH',
          body: retractApplicationConverter.toDb(body),
        };
      },
    }),
    confirmPrivacyPolicy: build.mutation<
      void,
      { applicationId: string; animalId: string }
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.CONFIRM_PRIVACY_POLICY}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    setApplicantsMoveInDate: build.mutation<void, TSetApplicantsMoveInDateUI[]>(
      {
        query(data) {
          return {
            url: `${baseUrl}/${EApplicationUrls.MOVE_IN_DATE}`,
            method: 'PATCH',
            body: {
              applicationMoveInDateDetails: (data || []).map(application =>
                setApplicantsMoveInDateConverter.toDb(application),
              ),
            },
          };
        },
      },
    ),
  }),
});

export const {
  useGetApplicationStepsQuery,
  useRetractApplicationMutation,
  useGetPaymentDetailsQuery,
  useGetStepVerificationMutation,
  useGetApplicationDetailQuery,
  useGetPMStepDetailsQuery,
  useCreateApplicationMutation,
  useSignApplicationMutation,
  useRecordApplicationStatisticsMutation,
  useGetLeasePacketsQuery,
  useGetLeasePacketPropertiesQuery,
  useGetLeasePacketUnitsQuery,
  useGetPropertyManagementsQuery,
  useConfirmPrivacyPolicyMutation,
  useSetApplicantsMoveInDateMutation,
} = applicationsApi;
