import moment, { Moment } from 'moment';
import { TFile } from './storage.types';
import {
  TSADocPacketGet,
  TSADocPacketGetUI,
  TSAPacketForm,
  TTrainerDocInfo,
} from './animalTraining.types';
import {
  EGender,
  EGenderUi,
  EValueEditingStates,
  TOptionType,
  TOptionTypeUi,
  TEditingBooleanField,
  TEditingField,
  TEditingFieldUi,
  TExpirationDateType,
  TVetRecordType,
  TFileDataType,
  TVetRecordTypeUI,
  TFailUI,
  TFail,
  TEditingYesNoFieldUi,
  TYesNoOptionType,
  EVerificationStatus,
  EVerificationStatusUi,
  EApplicationStatuses,
  EApplicationStatusesUi,
  TCustomOptionTypeUi,
  TCustomSpeciesOptionTypeUi,
  EReproductive,
  EAnimalStatusesUi,
  EAnimalStatuses,
  TPredefinedOptionType,
} from './generalTypes';
import {
  EHPDocPacketTypes,
  EHPDocPacketTypesUI,
  TDocPacketGet,
  TDocPacketGetUI,
  THPContactInfo,
  THPDocInfo,
  THPVisitation,
} from './docPacket.types';

export enum EAnimalTypesUI {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAnimalTypes {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAuthorizationStatusUI {
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
  NO_STATUS = 'No status',
}
export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
  NO_STATUS = 'NO_STATUS',
}

export type TOptionTypeUI = {
  key: string;
  id: string;
  value: string;
};

export type TAnimalDetailObjectProps = {
  value: string;
  predefined: boolean;
};

export type TAnimalWeightProps = {
  value: number;
  unit: string;
};

type TUnAuthorizedResidenciesProps = {
  propertyManagementName: string;
  propertyName: string;
};

type TAuthorization = {
  status?: EAuthorizationStatus;
  unAuthorizedResidencies?: TUnAuthorizedResidenciesProps[];
};
export enum EResidencyTypeUI {
  APPLICATION = 'Application',
  RESIDENCY = 'Residency',
}

export enum EResidencyTypeDB {
  APPLICATION = 'APPLICATION',
  RESIDENCY = 'RESIDENCY',
}
export type TConditionalDetailsUI = {
  dueDate: string;
  reason: string;
};
export type TConditionalDetailsDB = {
  dueDate: string;
  reason: string;
};
export type TAnimalApplicationData = {
  id: string;
  creationTime: string;
  leaseToDate: string | null;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  status: EApplicationStatuses;
  unitNumber: string;
  rejectReason: string;
  isComplete: boolean;
  canRetract: boolean;
  canMoveOut: boolean;
  residenceId: string;
  residencyType: EResidencyTypeDB;
  animalResidencyId: string | null;
  conditionalDetails: TConditionalDetailsDB | null;
};

export type TAnimalApplicationDataUI = {
  id: string;
  creationTime: string;
  leaseToDate: string | null;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  status: EApplicationStatusesUi;
  unitNumber: string;
  rejectReason: string;
  complete?: boolean;
  canRetract: boolean | null;
  residenceId: string;
  canMoveOut: boolean | null;
  residencyType: EResidencyTypeUI;
  animalResidencyId?: string;
  conditionalDetails: TConditionalDetailsUI | null;
};

export type TBlockedAction = {
  animalAction: EAnimalActionDB;
  reason: string;
};

export type TBlockedActionUI = {
  animalAction: EAnimalActionUI;
  reason: string;
};

export type TAnimalConversionToPetDB = {
  convertedToPetReason: string | null;
  isConvertedToPet: boolean;
  isConvertedToPetReasonViewed: boolean;
};

export type TAnimalConversionToPetUI = {
  convertedToPetReason: string | null;
  isConvertedToPet: boolean;
  isConvertedToPetReasonViewed: boolean;
  isReasonPredefined: boolean;
};

export type TAnimalPostUI = {
  applications: TAnimalApplicationDataUI[];
  id: string;
  gender: EGenderUi;
  reproduction: EReproductive | null;
  authorizationStatus?: EAuthorizationStatusUI;
  complete: boolean;
  name?: string;
  profilePicture?: string;
  status?: EAnimalStatusesUi;
  type?: EAnimalTypesUI;
  species?: string;
  breed?: string;
  weight?: number;
  actions?: EAnimalActionUI[];
  blockedActions: TBlockedActionUI[];
  conversion: TAnimalConversionToPetUI;
  verificationStatus: EVerificationStatusUi;
  statusUponDeletion: EAnimalStatusUponDeletionUI | null;
};

export type TAnimalGet = {
  residencies: TAnimalApplicationData[];
  id: string;
  gender: EGender;
  isReproductive: boolean | null;
  authorization?: TAuthorization;
  complete: boolean;
  name?: string;
  profilePicture?: string;
  type?: EAnimalTypes;
  status?: EAnimalStatuses;
  species?: TAnimalDetailObjectProps;
  breed?: TAnimalDetailObjectProps;
  weight?: TAnimalWeightProps;
  actions?: EAnimalActionDB[];
  blockedActions: TBlockedAction[];
  conversion: TAnimalConversionToPetDB;
  verificationStatus: EVerificationStatus;
  statusUponDeletion: EAnimalStatusUponDeletionDB | null;
};

export type TGenderUI = { gender: EGenderUi; neutered: TYesNoOptionType };
export type TGender = { type: EGender; isReproductive?: boolean | null };

export type TAnimalCreateReturnedDataDB = {
  animalId: string;
  isConvertedToPet: boolean;
};

export type TAnimalCreateReturnedDataUI = {
  id: string;
  isConvertedToPet: boolean;
};

export type TAnimalFormDataUI = {
  name: string;
  birthDate: moment.Moment;
  breed: TOptionTypeUi;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | null;
  gender: EGenderUi;
  neutered: TYesNoOptionType;
  houseTrained: TYesNoOptionType;
  animalType: EAnimalTypesUI;
  isAggressive: TYesNoOptionType;
  aggressionInfo?: string;
  microchip?: string;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
};

export type TAnimalFormData = {
  animalType: EAnimalTypes;
  name: string;
  birthDate: string;
  species: TOptionType;
  breed?: TOptionType | null;
  primaryColor: TOptionType;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained?: boolean | null;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
  aggressiveBehavior: {
    acted?: boolean | null;
    incidentDetails?: string;
    requiredBecauseOfDisability?: string;
    trainedFor?: string;
  };
  microchip?: string | null;
};
export type TCreateAnimalIncompleteUI = {
  name: string;
  type: { value: EAnimalTypesUI; label: EAnimalTypesUI };
  propertyManagementId: string;
  propertyName: string;
  unitNumber: string;
  moveInDate: Moment;
};

export type TAnimalIncompleteDB = {
  name: string;
  type: EAnimalTypesUI;
  id: string;
  applicationId?: string | null;
};

export type TAnimalIncompleteUI = {
  name: string;
  type: EAnimalTypesUI;
  id: string;
  applicationId?: string | null;
  moveInDate?: Moment;
};

export type TAnimalUI = {
  name: string;
  type: EAnimalTypesUI;
  id: string;
  status: EAnimalStatusesUi;
};

export type TCreateAnimalIncompleteDB = {
  name: string;
  type: EAnimalTypes;
  propertyManagementId: string;
  propertyName: string;
  unitNumber: string;
  moveInDate: string;
};
export type TBreedType = TOptionType | TCustomOptionTypeUi;
export type TBreedTypeUi = TOptionTypeUi;

export type TCompleteAnimalFormDataUI = {
  name: string;
  type: TOptionTypeUi;
  birthDate: moment.Moment;
  breed: TBreedTypeUi | null;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | string | null;
  gender: EGenderUi;
  neutered: TYesNoOptionType;
  houseTrained: TYesNoOptionType;
  animalId: string;
  isAggressive: TYesNoOptionType;
  aggressionInfo?: string;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
  microchip?: string;
};

export type TCompleteAnimalFormData = {
  animalId: string;
  animalType: TOptionTypeUi;
  name: string;
  birthDate: string;
  species: TOptionType | TCustomSpeciesOptionTypeUi;
  breed: TOptionType | TCustomOptionTypeUi | null;
  primaryColor: TOptionType | TCustomOptionTypeUi;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained?: boolean | null;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
  aggressiveBehavior: { acted?: boolean | null; incidentDetails?: string };
  microchip?: string;
};

export type TAnimalVetRecordsListUi = {
  documentFiles: TFileDataType[];
  id: string;
  locked: boolean;
  status: EVerificationStatusUi;
  vaccinationRecords: TVaccinationRecordUI[];
};

export type TAnimalVetRecordsList = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  locked: boolean;
  IsExpired: boolean;
  type: TVetRecordType;
  verificationStatus: EVerificationStatus;
  records: TVaccinationRecordDB[];
};

export type TVaccinationExpirationDB = {
  expirable: boolean;
  date?: string;
  expired: boolean;
};
export type TVaccinationExpirationUI = {
  expirable: boolean;
  date: string;
  expired: boolean;
};

export type TChangedRecordsUI = {
  type: TChangedRecordsTypeDB;
  expiration: TVaccinationExpirationUI;
  id?: string;
};
export type TChangedRecordsDB = {
  type: TChangedRecordsTypeUI;
  expiration: TVaccinationExpirationDB;
  id?: string;
};
export type TChangedRecordsTypeDB = {
  predefined?: string;
  custom?: string;
  value?: string;
};
export type TChangedRecordsTypeUI = {
  predefined?: string;
  custom?: string;
  value?: string;
};

export type TVaccinationRecordDB = {
  type: TVaccinationTypeDB;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TVaccinationRecordUI = {
  type: TVaccinationTypeUI;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};
export type TNewVaccinationRecordDB = {
  type: TChangedRecordsTypeDB;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TNewVaccinationRecordUI = {
  type: TChangedRecordsTypeUI;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TVaccinationTypeDB = {
  predefined: boolean;
  value: string;
};
export type TVaccinationTypeUI = {
  predefined?: boolean;
  value?: string;
};

export type TAnimalVetRecordCreateUi = {
  animalId: string;
  files: TFile[];
  docName: string;
  records: TVetRecordsUI[];
};

export type TAnimalVetRecordCreate = {
  animalId: string;
  records: TVetRecordsDB[];
  files: TFile[];
  docName: string;
};
export type TVetRecordsDB = {
  type: TVetRecordTypeUI;
  expiration?: TExpirationDateType;
};
export type TVetRecordsUI = {
  type: TVetRecordTypeUI;
  expiration?: TExpirationDateType;
};

export type TConfirmPaymentUi = {
  animalId: string;
  leasePacketId: string;
  propertyName?: string;
};

export type TConfirmPayment = TConfirmPaymentUi;

export type TVetRecordTypeNameUpdate = {
  animalId: string;
  vetRecordId: string;
  type: TVetRecordTypeUI;
};

export type TVetRecordTypeNameUpdateUI = {
  animalId: string;
  vetRecordId: string;
  type: string;
};

export type TVetRecordExpirationUpdate = {
  animalId: string;
  vetRecordId: string;
  expiration: TExpirationDateType;
};

export type TVetRecordExpirationUpdateUI = {
  animalId: string;
  vetRecordId: string;
  expirationDate: string;
};

export type TConvertAnimalToPetUI = {
  animalId: string;
};

export type TConvertAnimalToPet = {
  animalId: string;
};

export type TConvertAnimalToEsaUI = {
  cancelConversionRequest?: boolean;
  animalId: string;
  hasHpDoc?: boolean;
  conversionId?: string;
  requiredBecauseOfDisability: boolean;
  hpDocType?: EHPDocPacketTypesUI;
  name?: string;
  emailAddress?: string;
  hpReason?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string;
  websiteAdvertised?: string;
  hpDocPacketLetter?: TFile[];
  receivedAfterCompletingQuestionnaireOrInterview?: string;
};

export type TConvertAnimalToEsa = {
  animalId: string;
  conversionId?: string;
  requiredBecauseOfDisability?: boolean;
  hpDocInfo?: THPDocInfo;
  cancelConversionRequest: boolean | null;
};

export type TConvertAnimalToSaUI = TSAPacketForm & {
  hasHpDoc?: boolean;
  conversionId?: string;
  hasTrainerDoc?: boolean;
  animalId: string;
  trainedFor?: string;
  requiredBecauseOfDisability: boolean;
  observableDisability?: boolean;
  hpReason?: string;
  trainerReason?: string;
  hpDocType?: EHPDocPacketTypesUI;
  hpDocFormName?: string;
  hpDocFormEmailAddress?: string;
  hpDocFormPhoneNumber?: string;
  hpDocFormFaxNumber?: string;
  hpDocFormWebsites?: string[];
  websiteAdvertised?: string;
  websites?: string;
  hpDocPacketLetter?: TFile[];
  receivedAfterCompletingQuestionnaireOrInterview?: string;
};

export type TConvertAnimalToSa = {
  hasHpDoc?: boolean;
  conversionId?: string;
  hasTrainerDoc?: boolean;
  animalId: string;
  requiredBecauseOfDisability: boolean;
  trainedFor?: string;
  observableDisability?: boolean;
  hpDocInfo?: THPDocInfo;
  trainingDocInfo?: TTrainerDocInfo;
};

export type TUpdateAnimalConvHPDocsUI = {
  animalId: string;
  conversionId: string;
  hpDocType?: EHPDocPacketTypesUI;
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string;
  websiteAdvertised?: string;
  hpDocPacketLetter?: TFile[];
  receivedAfterCompletingQuestionnaireOrInterview?: string;
};

export type TUpdateAnimalConvHPDocs = {
  animalId: string;
  conversionId: string;
  hpDocumentPacketType?: EHPDocPacketTypes;
  hpContactInfo?: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
  receivedAfterCompletingQuestionnaireOrInterview?: boolean;
};
export type TVetRecordDocumentsDB = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFileDataType[];
  removedDocumentsId: string[];
  removedRecordIds: string[];
  newRecords: TNewVaccinationRecordDB[];
  changedRecords: TNewVaccinationRecordDB[];
};

export type TVetRecordDocumentsUI = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFileDataType[];
  removedDocumentsId: string[];
  removedRecordIds: string[];
  newRecords: TVaccinationRecordUI[];
  changedRecords: TVaccinationRecordUI[];
};
export type TVetRecordDocuments = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFile[];
  removedDocumentsId: string[];
};

export type TAnimalType = {
  animalId: string;
  newType: EAnimalTypes;
};

export type TAnimalTypeUI = {
  animalId: string;
  type: EAnimalTypesUI;
};

export type TVetRecordUI = {
  animalId: string;
  vetRecordId: string;
};

export type TVetRecord = {
  animalId: string;
  vetRecordId: string;
};

export type TEditingWeightField = {
  value?: number | null;
  unit?: string | null;
  allowedOperations: EValueEditingStates[];
};

export type TEditingWeightFieldUi = {
  value: string | null;
  unit: string | null;
  canEdit: boolean;
  canRemove: boolean;
};
export type TAnimalDetailsGenderField = {
  value: EGender;
  allowedOperations: EValueEditingStates[];
};
export type TAnimalDetailsGenderFieldUi = {
  value: EGenderUi;
  canEdit: boolean;
  canRemove: boolean;
};

export enum EAnimalActionDB {
  DELETE = 'DELETE',
  MOVE_OUT = 'MOVE_OUT',
  CONVERT = 'CONVERT',
}

export enum EAnimalActionUI {
  DELETE = 'DELETE',
  MOVE_OUT = 'MOVE_OUT',
  CONVERT = 'CONVERT',
}

export enum EConvertAnimalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum EConvertAnimalStatusUI {
  APPROVED = 'Converted',
  REJECTED = 'Not approved',
  PENDING = 'Pending',
}

export type TAnimalTypeConversion = {
  createdAt: string;
  trainedFor?: string;
  fromType: EAnimalTypes;
  hpDocumentPacket: TDocPacketGet | null;
  saDocumentPacket: TApplicationSADocPacketGet | null;
  id: string;
  reason: string | null;
  status: EConvertAnimalStatus;
  toType: EAnimalTypes;
  trainerDocumentInfoNotProvidedReason: string | null;
  hpDocumentInfoNotProvidedReason: string | null;
  observableDisability: boolean | null;
};

export type TAnimalTypeConversionUi = {
  createdAt: string;
  trainedFor?: string;
  fromType: EAnimalTypesUI;
  trainerDocumentInfoNotProvidedReason: string | null;
  hpDocumentInfoNotProvidedReason: string | null;
  observableDisability: boolean | null;
  hpDocumentPacket: TDocPacketGetUI | null;
  saDocumentPacket: TApplicationSADocPacketGetUI | null;
  id: string;
  reason: string | null;
  status: EConvertAnimalStatusUI;
  toType: EAnimalTypesUI;
  isPredefinedReason: boolean;
};

export type TAnimalDetails = {
  id: string;
  name: TEditingField;
  profilePicture: TEditingField;
  species: TEditingField;
  breed: TEditingField;
  conversions?: TAnimalTypeConversion[] | null;
  primaryColor: TEditingField;
  secondaryColor1: TEditingField;
  secondaryColor2: TEditingField;
  birthDate: TEditingField;
  weight: TEditingWeightField;
  isHouseTrained: TEditingBooleanField;
  gender: TAnimalDetailsGenderField;
  isReproductive: TEditingBooleanField;
  vetRecords: TAnimalVetRecordsList[] | null;
  saDocumentPackets: TSADocPacketGet[] | null;
  type: EAnimalTypes;
  status?: EVerificationStatus;
  authorization?: TAuthorization;
  actions?: EAnimalActionDB[];
  blockedActions?: TBlockedAction[];
  aggressiveBehavior: TAnimalAggressiveBehaviorDB;
  confirmAggressiveBehavior: boolean;
  statusUponDeletion: EAnimalStatusUponDeletionDB | null;
  microchip: string | null;
  trainedFor?: TEditingField;
  isRequiredBecauseOfDisability?: boolean;
};

export type TAnimalDetailsUi = {
  id: string;
  name: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  species: TEditingFieldUi;
  breed: TEditingFieldUi;
  conversions?: TAnimalTypeConversionUi[] | null;
  primaryColor: TEditingFieldUi;
  secondaryColor1: TEditingFieldUi;
  secondaryColor2: TEditingFieldUi;
  birthDate: TEditingFieldUi;
  weight: TEditingWeightFieldUi;
  isHouseTrained: TEditingYesNoFieldUi;
  gender: TAnimalDetailsGenderFieldUi;
  isReproductive: TEditingYesNoFieldUi;
  vetRecordList: TAnimalVetRecordsListUi[];
  packets: TSADocPacketGetUI[];
  type: EAnimalTypesUI;
  status?: EVerificationStatusUi;
  authorizationStatus?: EAuthorizationStatusUI;
  actions?: EAnimalActionUI[];
  blockedActions?: TBlockedActionUI[];
  aggressiveBehavior: TAnimalAggressiveBehaviorUI;
  confirmAggressiveBehavior: boolean;
  statusUponDeletion: EAnimalStatusUponDeletionUI | null;
  trainedFor?: TEditingFieldUi;
  isRequiredBecauseOfDisability?: boolean;
  microchip: string | null;
};

export type TAnimalDetailsById = {
  name?: TEditingField;
  type?: EAnimalTypes;
  profilePicture?: TEditingField;
  species?: TPredefinedOptionType | null;
  breed?: TPredefinedOptionType | null;
  primaryColor?: TPredefinedOptionType | null;
  secondaryColor1?: TPredefinedOptionType | null;
  secondaryColor2?: TPredefinedOptionType | null;
  birthDate?: TEditingField;
  weight?: TEditingWeightField;
  isHouseTrained?: TEditingBooleanField;
  gender?: TAnimalDetailsGenderField;
  isReproductive?: TEditingBooleanField;
  trainedFor: { value?: string };
  aggressiveBehavior: TAnimalAggressiveBehaviorDB;
  microchip: string | null;
};

export type TAnimalDetailsByIdUi = {
  name?: string | null;
  type?: TOptionTypeUi | null;
  profilePicture?: string | null;
  species?: TOptionTypeUi | null;
  breed?: TOptionTypeUi | null;
  primaryColor?: TOptionTypeUi | null;
  secondaryColor1?: TOptionTypeUi | null;
  secondaryColor2?: TOptionTypeUi | null;
  birthDate?: moment.Moment | null;
  weight?: number | null;
  houseTrained?: string | null;
  gender?: string;
  neutered?: string | null;
  trainedFor: string;
  aggressiveBehavior: TAnimalAggressiveBehaviorUI;
  microchip: string | null;
};

export type TAnimalMetadata = {
  description: string | null;
  hasEsaLetterForAnimal: boolean | null;
  trainedForObservableDisability: boolean | null;
  trainedForObservableDisabilityDescription: string | null;
};

export type TAnimalMetadataUi = {
  description: string | null;
  hasEsaLetterForAnimal: TYesNoOptionType;
  trainedForObservableDisability: TYesNoOptionType;
  trainedForObservableDisabilityDescription: string | null;
};

export type TAnimalFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalCreatableFieldUpdateUi = {
  id: string;
  value: TOptionTypeUi;
};

export type TAnimalCreateFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalNameUpdate = {
  animalId: string;
  name: string;
};
export type TAnimalNameUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalHouseTrainedUpdate = {
  animalId: string;
  houseTrained?: boolean | null;
};
export type TAnimalHouseTrainedUpdateUi = {
  id: string;
  value: TYesNoOptionType;
};
export type TAnimalGenderUpdate = {
  animalId: string;
  gender: EGender;
};
export type TAnimalGenderUpdateUi = {
  id: string;
  value: EGenderUi;
};
export type TAnimalProfileUpdate = {
  animalId: string;
  profilePicture: string;
};
export type TAnimalProfileUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalWeightUpdate = {
  animalId: string;
  weight: string | null;
  unit: string;
};
export type TAnimalWeightUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalTrainedForUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalTrainedForUpdate = {
  animalId: string;
  trainedFor: string;
};
export type TAnimalBirthDate = {
  animalId: string;
  birthDate: string;
};
export type TAnimalBirthDateUi = TAnimalFieldUpdateUi;
export type TAnimalSecondaryColorUpdate = {
  animalId: string;
  colorId: string;
};
export type TAnimalSecondaryColorUpdateUi = TAnimalCreateFieldUpdateUi;
export type TAnimalReproductiveUpdate = {
  animalId: string;
  reproductive?: boolean | null;
};
export type TAnimalReproductiveUpdateUi = {
  id: string;
  value: TYesNoOptionType;
};
export type TAnimalSpeciesUpdate = {
  animalId: string;
  species: TOptionType;
};
export type TAnimalSpeciesUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalPrimaryColorUpdate = {
  animalId: string;
  primaryColor: TOptionType;
};
export type TAnimalPrimaryColorUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalBreedUpdate = {
  animalId: string;
  breed: TOptionType;
};

export type TAnimalBreedUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalSecondaryColor1Update = {
  animalId: string;
  colorId: string;
};

export type TApplicationSADocPacketGetUI = TSADocPacketGetUI & {
  fails: TFailUI[];
};
export type TApplicationSADocPacketGet = TSADocPacketGet & { fails?: TFail };

export type TApplicationVetRecordsUI = TAnimalVetRecordsListUi & {
  fails: TFailUI[];
};
export type TApplicationVetRecords = TAnimalVetRecordsList & {
  fails?: TFail;
};

export type TApplicationAnimalUI = Omit<
  TAnimalDetailsUi,
  'vetRecordList' | 'packets' | 'actions'
> & {
  fails: TFailUI[];
  documentsFails?: TFailUI[];
  vetRecordList: TApplicationVetRecordsUI[];
  packets: TApplicationSADocPacketGetUI[];
};

export type TApplicationAnimal = Omit<
  TAnimalDetails,
  'vetRecords' | 'saDocumentPackets' | 'actions'
> & {
  fails?: TFail;
  vetRecords: TApplicationVetRecords[] | null;
  saDocumentPackets: TApplicationSADocPacketGet[] | null;
};

export enum EMoveOutOrRetractReasonTypeDB {
  ANIMAL_HAS_PASSED_AWAY = 'ANIMAL_HAS_PASSED_AWAY',
  ANIMAL_HAS_RE_HOMED = 'ANIMAL_HAS_RE_HOMED',
}

export enum EMoveOutOrRetractReasonTypeUI {
  ANIMAL_HAS_PASSED_AWAY = 'My animal has passed away ',
  ANIMAL_HAS_RE_HOMED = 'I have re-homed my animal',
}

export type TMoveOutReasonDB = {
  predefined?: EMoveOutOrRetractReasonTypeDB;
  other?: string;
};

export type TMoveOutReasonUI = {
  predefinedType?: EMoveOutOrRetractReasonTypeUI;
  other?: string;
};

export type TMoveOutFormDB = {
  animalId: string;
  reason: TMoveOutReasonDB;
  deleteAnimal?: boolean;
};

export type TMoveOutFormUI = {
  animalId: string;
  reason: EMoveOutOrRetractReasonTypeUI;
  other: string;
  deleteAnimal?: boolean;
};

export type TMoveOutFromResidencyFormDB = {
  animalIds: string[];
  residencyId: string;
  moveOutReason: TMoveOutReasonDB;
  deleteAnimal?: boolean;
};

export type TMoveOutFromResidencyFormUI = {
  animalIds: string[];
  residencyId: string;
  reason: EMoveOutOrRetractReasonTypeUI;
  other: string;
  deleteAnimal?: boolean;
};

export type TUnAuthorizedResidencyDB = {
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
};

export type TUnAuthorizedResidencyUI = {
  propertyName: string;
  propertyManagementName: string;
  unitNumber: string;
};

export type TIncompleteAnimalsDataDB = {
  count: number;
  animalId: string;
  applicationId: string;
  incompleteResource: 'ANIMAL' | 'APPLICATION';
};
export type TIncompleteAnimalsDataUI = {
  count: number;
  animalId: string;
  applicationId: string;
  incompleteResource: 'ANIMAL' | 'APPLICATION';
};

export type TAnimalSpecieDB = {
  value: string;
  predefinedId: string;
  predefined: boolean;
};
export type TAnimalSpecieUI = {
  value: string;
  predefinedId: string;
  predefined: boolean;
};

export type TPotentialDuplicationAnimalsDB = {
  ownerName: string;
  ownerUsername: string;
  animalId: string;
  animalName: string;
  animalType: string;
  animalSpecie: TAnimalSpecieDB | null;
};
export type TPotentialDuplicationAnimalsUI = {
  ownerName: string;
  ownerUsername: string;
  animalId: string;
  animalName: string;
  animalType: string;
  animalSpecie: TAnimalSpecieUI | null;
};

export type TAnimalDuplicationDB = {
  createdAnimalId: string | null;
  failure: string | null;
  potentialDuplicateAnimals: TPotentialDuplicationAnimalsDB[];
};
export type TAnimalDuplicationUI = {
  createdAnimalId: string | null;
  failure: string | null;
  potentialDuplicateAnimals: TPotentialDuplicationAnimalsUI[];
};

export type TRegisterAnimalDB = {
  animalId?: string;
};
export type TRegisterAnimalUI = {
  animalId?: string;
};

export type TDuplicateCheckDB = {
  animalId: string;
  tenantResidencyId?: string;
};

export type TDuplicateCheckUI = {
  animalId: string;
  residencyId?: string;
};

export type TDuplicationPreventionDB = {
  animalId: string;
  animalName: string;
  animalSpice: string | null;
  animalType: EAnimalTypes;
  ownerName: string;
  ownerUsername: string;
  status: EApplicationStatuses;
};

export type TDuplicationPreventionUI = {
  animalId: string;
  animalName: string;
  animalSpice: string | null;
  animalType: EAnimalTypesUI;
  ownerName: string;
  ownerUsername: string;
  status: EApplicationStatusesUi;
};

export type TAnimalAggressiveBehaviorDB = {
  acted: boolean | null | undefined;
  incidentDetails?: string;
  allowedOperations?: EValueEditingStates[];
};

export type TAnimalAggressiveBehaviorUI = {
  acted: TYesNoOptionType;
  incidentDetails?: string;
  canEdit?: boolean;
  canRemove?: boolean;
  canAdd?: boolean;
};

export type TAnimalAggressionUpdateDB = {
  animalId: string;
  aggressiveBehavior: TAnimalAggressiveBehaviorDB;
};

export type TAnimalAggressionUpdateUI = {
  id: string;
  aggressiveBehavior: TAnimalAggressiveBehaviorUI;
};

export type TAnimalMicrochipDB = {
  animalId: string;
  microchip: string;
};
export type TAnimalMicrochipUI = {
  id: string;
  microchip: string;
};

export type TConfirmAnimalTypeDB = {
  animalId: string;
  toType: string;
};
export type TConfirmAnimalTypeUI = {
  animalId: string;
  toType: string;
};

export enum EAnimalStatusUponDeletionDB {
  APPROVED = 'APPROVED',
  MOVED_IN = 'MOVED_IN',
  PENDING = 'PENDING',
}

export enum EAnimalStatusUponDeletionUI {
  APPROVED = 'APPROVED',
  MOVED_IN = 'MOVED_IN',
  PENDING = 'PENDING',
}

export type TDeleteAnimalDB = {
  animalId: string;
  moveOutReason?: TMoveOutReasonDB;
  retractionReason?: string;
};
export type TDeleteAnimalUI = {
  animalId: string;
  moveOutReason?: EMoveOutOrRetractReasonTypeUI;
  retractionReason?: string;
  otherReason?: string;
};

export type TConvertedToPetAcknowledgmentDB = {
  convertedToPetReason: string;
  id: string;
  name: string;
  species: TOptionType;
};
export type TConvertedToPetAcknowledgmentUI = {
  convertedToPetReason: string;
  id: string;
  name: string;
  species?: string;
  isReasonPredefined: boolean;
};

export enum EConvertedToPetResolve {
  REQUEST_TO_CONVERT = 'REQUEST_TO_CONVERT',
  UNDERSTOOD = 'UNDERSTOOD',
}

export type TConvertedToPetResolveDB = {
  animalId: string;
  status: EConvertedToPetResolve;
};

export type TConvertedToPetResolveUI = {
  animalId: string;
  status: EConvertedToPetResolve;
};
